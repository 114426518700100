import React, { useContext } from "react"
import styled from "styled-components"
import Link from "shared/components/Link"
import usePageTitle from "mill/hooks/usePageTitle"
import { md } from "mill/utils/breakpoints"

import FourZeroFour from "./404.png"

export const Background = styled.div`
  background: #e5e5e5;
  min-height: 100vh;
  width: 100%;

  > div {
    text-align: center;
    overflow: hidden;
    background-color: white;

    & > div {
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
      background-image: url("${FourZeroFour}");
      position: relative;
      margin-top: 30rem;

      &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 40%;
      }

      & > div {
        margin-top: -30rem;
        margin-bottom: 30rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 1.5rem;
      }
    }
  }

  h1 {
    text-transform: uppercase;
    color: ${props => props.theme && props.theme.colors.secondary};
    font-weight: 300;
    font-family: "Raleway";
    margin-top: 5rem;
    margin-bottom: 1.5rem;
    font-size: 3rem;
    line-height: 4rem;
    margin-top: 3rem;
  }

  h2 {
    color: ${props => props.theme && props.theme.colors.primary};
    font-size: 2.3rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  a {
    color: ${props => props.theme && props.theme.colors.primary};
  }

  p {
    font-size: 1.5rem;
    max-width: 80rem;
    margin: 0 auto;
  }

  @media ${md} {
    > div > div > div {
      margin-bottom: 0rem;
      margin-top: -25rem;
    }

    h1 {
      font-size: 5rem;
      line-height: 5rem;
    }

    h2 {
      font-size: 3rem;
    }

    p {
      font-size: 1.7rem;
      max-width: 60rem;
    }
  }
`

const NotFound = () => {
  usePageTitle("Not Found")

  return (
    <Background>
      <div>
        <div>
          <div>
            <h1>MAYDAY!! MAYDAY!!</h1>
            <h2>LOOKS LIKE YOUR NAVIGATION SYSTEM IS DOWN.</h2>
            <p>
              Unfortunately this page doesn’t exist. You can head back to{" "}
              <Link to={"/"}>home</Link>, or enjoy a few articles from our{" "}
              <a href="https://www.yarno.com.au/blog" title="Yarno Blog">
                blog
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </Background>
  )
}

export default NotFound
