import styled from "styled-components"
import React, { useState } from "react"
import colors from "shared/utils/colors"

export const FaqsContainer = styled.div`
  h2 {
    color: #707273;
  }
`

const FaqContainer = styled.div`
  border-bottom: 1px solid ${colors.grayAthens};
`

const QuestionHeader = styled.div`
  color: #7f7f7f;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h4 {
    margin: 2rem 0;
    font-weight: 600;
  }

  i {
    color: ${props => props.theme.colors.primary};
    ${props =>
      props.isOpen &&
      `
      transform: scaleY(-1);
      -webkit-transition: 0.2s all ease;
      transition: 0.2s all ease;
    `}
  }

  &:hover {
    cursor: pointer;
  }
`

const Question = styled.h4`
  color: #7f7f7f;
`

const Explanation = styled.div`
  color: #7f7f7f;
  border-left: 3px solid ${props => props.theme.colors.primary};
  padding-left: 2rem;

  p {
    margin-top: 0;
  }
`

export const FAQ = props => {
  const [showExplanation, setShowExplanation] = useState(false)
  return (
    <FaqContainer>
      <QuestionHeader
        isOpen={showExplanation}
        onClick={() => setShowExplanation(!showExplanation)}>
        <Question>{props.question}</Question>
        <i className="fa fa-angle-down" />
      </QuestionHeader>
      {showExplanation && <Explanation>{props.children}</Explanation>}
    </FaqContainer>
  )
}
